import React from 'react'
import useArray from './hooks/useArray'
import findLastIndex from './utils/findLastIndex'

export type DialogContextType = {
    ids: Array<string>
    add: (id: string) => void
    remove: (id?: 'ALL' | string) => void
}

const DialogContext = React.createContext<DialogContextType | undefined>(undefined)

DialogContext.displayName = 'DialogContext'

const DialogProvider = ({ children }) => {
    const { array: dialogs, set: setDialogs, update: updateDialog, push: pushDialog, remove: removeDialog } = useArray([])

    const remove = React.useCallback(
        (id?: 'ALL' | string) => {
            if (!dialogs.length) return

            if (!id) {
                removeDialog(dialogs.length - 1)
                return
            }

            if (id === 'ALL') {
                setDialogs([])
                return
            }

            const target = dialogs.findIndex(d => d === id)
            if (target === -1) return

            removeDialog(target)
        },
        [dialogs]
    )

    const add = React.useCallback(
        (id: string) => {
            setDialogs([...new Set(dialogs.concat(id))])
        },
        [dialogs]
    )

    const value = React.useMemo(
        () => ({
            ids: dialogs,
            add,
            remove,
        }),
        [dialogs, add, remove]
    )

    return <DialogContext.Provider value={value}>{children}</DialogContext.Provider>
}

export { DialogContext, DialogProvider }
