import React, { useEffect, useState } from 'react';
import { DialogContext } from './../dialogContext';

export default function useDialog(id: string) {
    const { add, remove, ids } = React.useContext(DialogContext)

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (ids?.length) {
            setIsOpen(ids.includes(id));
        }
    }, [ids, id]);

    const show = () => {
        add(id)
    }
    const hide = () => {
        remove(id)
    }



    return { show, hide, id, isOpen }
}
